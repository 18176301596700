<route>
{
  "meta": {
    "permission": [
      "purchase.view_purchaseorder"
    ]
  }
}
</route>

<template>
  <v-container fluid>
    <v-tabs
      class="mb-2"
      color="primary"
      v-model="tabs"
      next-icon="fa-chevron-right"
      prev-icon="fa-chevron-left"
      centered
      show-arrows
      :icons-and-text="$vuetify.breakpoint.smAndUp"
    >
      <v-tab class="pt-3">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['all'])"
        >
          <span
            class="pt-2"
            v-if="$vuetify.breakpoint.smAndUp"
            v-text="$t('all')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fas fa-file</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#draft">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['draft'])"
        >
          <span
            class="pt-2"
            v-if="$vuetify.breakpoint.smAndUp"
            v-text="$t('draft')"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp"
            >fas fa-file-signature</v-icon
          >
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#pdocument">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['pdocument'])"
        >
          <span
            class="pt-2"
            v-text="$t('purchase_order')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp"
            >fa-file-contract</v-icon
          >
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#sended_document">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['sended_document'])"
        >
          <span
            class="pt-2"
            v-text="$t('sended_document')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp"
            >fa-file-contract</v-icon
          >
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#in_process">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['in_process'])"
        >
          <span
            class="pt-2"
            v-text="$t('in_process')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-image</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#reception">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['reception'])"
        >
          <span
            class="pt-2"
            v-text="$t('reception')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-image</v-icon>
        </v-badge>
      </v-tab>
      <v-tab class="pt-3" href="#finished">
        <v-badge
          class="d-flex flex-column-reverse"
          bordered
          :content="String(orderStatus['finished'])"
        >
          <span
            class="pt-2"
            v-text="$t('finished')"
            v-if="$vuetify.breakpoint.smAndUp"
          ></span>
          <v-icon :medium="$vuetify.breakpoint.smAndUp">fa-file-archive</v-icon>
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-card>
      <div v-if="tabs === 0">
        <i-card-list
          :title="$tc('suborder', 2)"
          app="purchase.order"
          api="purchase.product"
          dontCreate
          dontRemove
          dontEdit
          simple
          :opt="{ company_id: this.firstCompany }"
        >
          <template v-slot:[`item`]="{ item, showMode }">
            <v-row no-gutters>
              <v-card-text>
                <v-row no-gutters align="center">
                  <v-col cols="2" v-if="showMode === 'grid'"></v-col>
                  <v-col cols="2" v-if="showMode !== 'simple'">
                    <i-image
                      class="product-image"
                      :style="{
                        width: '10em !important',
                        height: '8em !important'
                      }"
                      :value="item.thumbnail"
                      :preview="true"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    :sm="showMode != 'grid' ? 2 : 12"
                    :md="showMode != 'grid' ? 2 : 12"
                  >
                    <v-row>
                      <v-col cols="12">
                        <span class="primary--text font-weight-black">
                          {{ `${$tc('supplier', 1)}: ` }}
                        </span>
                        <span class="primary--text subtitle-2">
                          {{ item.infoSupplierName }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="primary--text font-weight-black">
                          {{ `${$tc('total', 1)}: ` }}
                        </span>
                        <span class="primary--text subtitle-2">
                          {{ item.total }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="primary--text font-weight-black">
                          {{ `${$tc('purchasesReceiver', 1)}: ` }}
                        </span>
                        <span class="primary--text subtitle-2">
                          {{ item.receiverName }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    :sm="showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12"
                    :md="showMode == 'simple' ? 7 : showMode == 'list' ? 5 : 12"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span
                            class="primary--text subtitle-2 font-weight-black"
                          >
                            {{ $tc('suborder', 1) }} ({{ item.own_prod }})
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <br v-if="showMode === 'grid'" />
                  </v-col>
                  <v-col
                    class="grey--text ligthen-3 font-weight-bold caption"
                    sm="3"
                    v-show="showMode !== 'grid' && $vuetify.breakpoint.mdAndUp"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" align="end" class="mt-2">
                        <span>{{ $t('date') }}</span>
                        <span class="primary--text font-weight-black">
                          {{ `${dateFormat(item.orderDate)}` }}
                        </span>
                        <br v-show="item.expectedDate != null" />
                        <span v-show="item.expectedDate != null">
                          {{ `${$t('expected_date')}` }}
                        </span>
                        <span
                          v-show="item.expectedDate != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.expectedDate)}` }}
                        </span>
                        <br v-show="item.dateDone != null" />
                        <span v-show="item.dateDone != null">
                          {{ `${$t('dateDone')}` }}
                        </span>
                        <span
                          v-show="item.dateDone != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.dateDone)}` }}
                        </span>
                      </v-col>
                      <v-col cols="12" align="end">
                        <span>
                          {{ `${$t('orderstatus')}` }}
                        </span>
                        <span class="primary--text font-weight-black">
                          {{ `${$t(item.orderMain)}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="grey--text ligthen-3 font-weight-bold caption"
                    align="end"
                    cols="12"
                    v-show="$vuetify.breakpoint.smAndDown || showMode == 'grid'"
                  >
                    <br />
                    <v-row no-gutters>
                      <v-col cols="12" align="end" class="mt-2">
                        <span>{{ $t('date') }}</span>
                        <span class="primary--text font-weight-black">
                          {{ `${dateFormat(item.orderDate)}` }}
                        </span>
                        <br v-show="item.expectedDate != null" />
                        <span v-show="item.expectedDate != null">
                          {{ `${$t('expected_date')}` }}
                        </span>
                        <span
                          v-show="item.expectedDate != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.expectedDate)}` }}
                        </span>
                        <br v-show="item.dateDone != null" />
                        <span v-show="item.dateDone != null">
                          {{ `${$t('dateDone')}` }}
                        </span>
                        <span
                          v-show="item.dateDone != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.dateDone)}` }}
                        </span>
                      </v-col>
                      <v-col cols="12" align="end">
                        <span>
                          {{ `${$t('orderstatus')}:` }}
                        </span>
                        <span class="primary--text font-weight-black">
                          {{ `${$t(item.orderMain)}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </template>
        </i-card-list>
      </div>
      <div v-if="tabs !== 0">
        <i-card-list
          class="mt-2 px-2"
          :title="$t('purchasesorderlist')"
          dontHeader
          dontColor
          dontRemove
          simple
          app="purchase.purchaseorder"
          api="purchase"
          :method="'list'"
          :opt="{ status: this.tabs, company_id: this.firstCompany }"
          :reload.sync="reload"
          @click:create="
            purchaseorderBtn.add ? changeRoute({ pk: 'create' }) : ''
          "
          @click:edit="
            purchaseorderBtn.edit ? changeRoute({ pk: $event.pk }) : ''
          "
        >
          <template v-slot:[`item`]="{ item, showMode, remove }">
            <v-row v-if="tabs !== 0" no-gutters>
              <v-col align="center" v-if="showMode == 'grid'">
                <i-image
                  class="product-image"
                  :style="{
                    width: '10em !important',
                    height: '8em !important'
                  }"
                  :value="item.thumbnail"
                  :preview="true"
                  :readonly="true"
                />
              </v-col>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="2" v-if="showMode == 'list'">
                    <i-image
                      class="product-image"
                      :style="{
                        width: '10em !important',
                        height: '8em !important'
                      }"
                      :value="item.thumbnail"
                      :preview="true"
                      :readonly="true"
                    />
                  </v-col>
                  <v-col
                    class="primary--text font-weight-bold caption"
                    align="end"
                    cols="12"
                    v-show="$vuetify.breakpoint.smAndDown || showMode == 'grid'"
                  >
                    {{ `${item.code != null ? item.code : ''}` }}
                    <v-menu
                      offset-y
                      v-show="showMode !== 'simple'"
                      class="mx-0"
                      v-if="purchaseorderBtn.delete"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          style="margin-top: -0.1rem"
                          v-if="$vuetify.breakpoint.mdAndUp"
                          color="primary"
                          text
                          small
                          dark
                          v-on="on"
                          @click.stop=""
                        >
                          {{ $t('action') }}
                          <v-icon style="margin-left: 5px" size="10"
                            >fa-chevron-down</v-icon
                          >
                        </v-btn>
                        <v-btn
                          v-else
                          style="margin-top: -0.1rem"
                          color="primary"
                          small
                          text
                          dark
                          v-on="on"
                          @click.stop=""
                        >
                          <v-icon size="20">fa-ellipsis-v</v-icon>
                        </v-btn>
                      </template>
                      <v-list align="center">
                        <v-list-item>
                          <i-btn
                            :title="$tc('delete', 1)"
                            outlined
                            classes="mx-1"
                            icon="fa-trash"
                            color="error"
                            @click.prevent="remove(item.pk)"
                          />
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    :sm="showMode != 'grid' ? 3 : 12"
                    :md="showMode != 'grid' ? 3 : 12"
                  >
                    <v-row no-gutters>
                      <v-col cols="12">
                        <span
                          :class="{
                            'subtitle-1': true,
                            'font-weight-black': true,
                            'primary--text': true
                          }"
                        >
                          {{ `${item.nameOrder}` }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="primary--text font-weight-black">
                          {{ `${$tc('supplier', 1)}: ` }}
                        </span>
                        <span class="primary--text subtitle-2">
                          {{ item.name_infoSupplier }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <span class="primary--text font-weight-black">
                          {{ `${$tc('purchasesReceiver', 1)}: ` }}
                        </span>
                        <span class="primary--text subtitle-2">
                          {{ item.name_purchasesReceiver }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    :sm="showMode == 'simple' ? 6 : showMode == 'list' ? 4 : 12"
                    :md="showMode == 'simple' ? 6 : showMode == 'list' ? 4 : 12"
                  >
                    <span
                      v-if="item.number_suborder"
                      class="primary--text subtitle-2 font-weight-black"
                    >
                      {{ $tc('product', 2) }} ({{ item.number_suborder }})
                    </span>
                  </v-col>
                  <v-col
                    class="grey--text ligthen-3 font-weight-bold caption"
                    sm="3"
                    v-show="showMode !== 'grid' && $vuetify.breakpoint.mdAndUp"
                  >
                    <v-row no-gutters>
                      <v-col cols="12" align="end" class="primary--text">
                        {{ `${item.code != null ? item.code : ''}` }}
                        <v-menu
                          offset-y
                          v-show="showMode == 'grid'"
                          class="mx-0"
                          v-if="purchaseorderBtn.delete"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              style="margin-top: -0.1rem"
                              v-if="$vuetify.breakpoint.mdAndUp"
                              color="primary"
                              text
                              small
                              dark
                              v-on="on"
                              @click.stop=""
                            >
                              {{ $t('action') }}
                              <v-icon style="margin-left: 5px" size="10"
                                >fa-chevron-down</v-icon
                              >
                            </v-btn>
                            <v-btn
                              v-else
                              style="margin-top: -0.1rem"
                              color="primary"
                              small
                              text
                              dark
                              v-on="on"
                              @click.stop=""
                            >
                              <v-icon size="20">fa-ellipsis-v</v-icon>
                            </v-btn>
                          </template>
                          <v-list align="center">
                            <v-list-item>
                              <i-btn
                                :title="$tc('delete', 1)"
                                outlined
                                classes="mx-1"
                                icon="fa-trash"
                                color="error"
                                @click.prevent="remove(item.pk)"
                              />
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" align="end" class="mt-2">
                        <span>{{ $t('date') }}</span>
                        <span class="primary--text font-weight-black">
                          {{ `${dateFormat(item.date)}` }}
                        </span>
                        <br v-show="item.expected_date != null" />
                        <span v-show="item.expected_date != null">
                          {{ `${$t('expected_date')} ` }}
                        </span>
                        <span
                          v-show="item.expected_date != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.expected_date)}` }}
                        </span>
                        <br v-show="item.dateDone != null" />
                        <span v-show="item.dateDone != null">
                          {{ `${$t('dateDone')}` }}
                        </span>
                        <span
                          v-show="item.dateDone != null"
                          class="primary--text font-weight-black"
                        >
                          {{ `${dateFormat(item.dateDone)}` }}
                        </span>
                      </v-col>
                      <v-col cols="12" align="end">
                        <span>
                          {{ `${$t('orderstatus')}` }}
                        </span>
                        <span class="primary--text font-weight-black">
                          {{ `${$t(item.mainStatus)}` }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    class="grey--text ligthen-3 font-weight-bold caption"
                    align="end"
                    cols="12"
                    v-show="$vuetify.breakpoint.smAndDown || showMode == 'grid'"
                  >
                    <span>{{ $t('date') }}</span>
                    <span class="primary--text font-weight-black">
                      {{ `${dateFormat(item.date)}` }}
                    </span>
                    <br v-show="item.expected_date != null" />
                    <span v-show="item.expected_date != null">
                      {{ `${$t('expected_date')}` }}
                    </span>
                    <span
                      v-show="item.expected_date != null"
                      class="primary--text font-weight-black"
                    >
                      {{ `${dateFormat(item.expected_date)}` }}
                    </span>
                    <br v-show="item.dateDone != null" />
                    <span v-show="item.dateDone != null">
                      {{ `${$t('dateDone')}` }}
                    </span>
                    <span class="primary--text font-weight-black">
                      {{ `${dateFormat(item.dateDone)}` }}
                    </span>
                    <br
                      v-show="$vuetify.breakpoint.xsOnly || showMode == 'grid'"
                    />
                    <span class="ml-5">
                      {{ `${$t('state')}` }}
                    </span>
                    <span class="primary--text font-weight-black">
                      {{ `${$t(item.mainStatus)}` }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-row>
          </template>
        </i-card-list>
      </div>
      <v-btn
        fab
        dark
        color="secondary"
        v-show="!$store.getters.getIsloading"
        fixed
        bottom
        right
        v-scroll="onScroll"
        :class="{ 'mr-3': true, 'mb-3': $vuetify.breakpoint.xsOnly }"
        @click="$vuetify.goTo(positionScroll ? 0 : '#pagination')"
      >
        <v-icon
          dark
          v-text="positionScroll ? 'fa-chevron-up' : 'fa-chevron-down'"
        >
        </v-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      orderStatus: {
        all: 0,
        draft: 0,
        pdocument: 0,
        sended_document: 0,
        in_process: 0,
        reception: 0,
        finished: 0,
        canceled: 0,
        correction: 0
      },
      orders: [],
      loading: false,
      positionScroll: 0,
      enableSearch: false,
      tabs: 'all',
      reload: false,
      firstCompany: 0
    }
  },
  computed: {
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin',
      myCompanies: 'session/myCompanies'
    }),
    purchaseorderBtn() {
      return {
        add:
          (this.getPermissions([`purchase.add_purchaseorder`]) &&
            this.getPermissions([`base_config.view_term`]) &&
            this.getPermissions([`supplier.view_supplier`])) ||
          this.isAdmin,
        edit:
          (this.getPermissions([`purchase.change_purchaseorder`]) &&
            this.getPermissions([`base_config.view_term`]) &&
            this.getPermissions([`supplier.view_supplier`]) &&
            this.getPermissions([`product.view_product`]) &&
            this.getPermissions([`base_config.view_globaltax`])) ||
          this.isAdmin,
        delete:
          this.getPermissions([`purchase.delete_purchaseorder`]) || this.isAdmin
      }
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.positionScroll = top > 60
    },
    async getPurchaseOrders() {
      try {
        this.loading = true
        const response = await this.$api.purchase.list({
          opt: {
            params: {
              company_id: this.firstCompany,
              status: this.tabs
            }
          }
        })
        this.orders = response.data.results
        const status = await this.$api.purchase.counter({
          opt: {
            params: {
              company_id: this.firstCompany
            }
          }
        })
        this.orderStatus = status.data
        this.orderStatus.all =
          status.data.draft +
          status.data.pdocument +
          status.data.sended_document +
          status.data.in_process +
          status.data.reception +
          status.data.finished
      } finally {
        this.loading = false
      }
    },
    changeRoute(item) {
      this.$router.push({
        name: 'purchase-pk',
        params: { pk: this.codification({ code: item.pk }) }
      })
    },
    /**
     * setCompany
     * Método para establecer la primera empresa a la que pertenece el usuario
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    setCompany() {
      if (this.myCompanies.length > 0) {
        this.firstCompany = this.myCompanies[0].id
      }
    }
  },
  created() {
    this.setCompany()
  },
  mounted() {
    this.getPurchaseOrders()
  },
  watch: {
    tabs() {
      this.getPurchaseOrders()
    },
    '$vuetify.breakpoint.xsOnly': {
      handler(val) {
        if (val) {
          this.showMode = 'simple'
        }
      }
    }
  }
}
</script>
